















































































































































































































































































/* eslint-disable */
import { useActions, useMutations, useState } from '@u3u/vue-hooks'
import { defineComponent, ref } from '@vue/composition-api'

import Tip from '@/components/Tip.vue'
import UploadPanel from '@/components/UploadPanel.vue'
import ConsentementPanel from '@/components/ConsentementPanel.vue'
import axios from 'axios'
import { getApiUrl } from '@/inc/app.config'
import { logger } from '@/inc/utils'
import moment from 'moment'

export default defineComponent({
  name: 'upload-record-header',
  components: { Tip },
  props: {
    parentData: {
      type: Object,
      required: true,
    },
  },

  setup(_props, ctx) {
    const { files, i18n, i18nSuivi, consentement } = useState('my', [
      'files',
      'i18n',
      'i18nSuivi',
      'consentement',
    ])
    const { SET_CONSENTEMENT, UPDATE_FILE } = useMutations('my', [
      'SET_CONSENTEMENT',
      'UPDATE_FILE',
    ])
    const listeDossiers = ref<any | null>()
    const allFilesSended = ref(false)
    const { showPanel } = useActions('sidePanel', ['showPanel'])
    const showLoader = ref(false)
    const isSend = ref(false)
    const isDevelop = ref(true)
    const loaderRenvoyer = ref(false)
    /**
     *  Fonction permettant de recuperer les derniers infos depuis WS
     */
    if (_props.parentData.length > 0) {
      listeDossiers.value = _props.parentData
    } else {
      axios
        .post(`${getApiUrl()}/works/records`, {
          NumDossier: [_props.parentData.numDossier[0]],
        })
        .then(response => {
          if (response.status === 200) {
            listeDossiers.value = response.data.liste
          }
          showLoader.value = true
        })
        .catch(e => {
          logger.error('[FETCH DATA] ERROR WS 59')
        })
    }
    /**
     *  develop : retourne vrai ou faux pour l'ouverture et fermeture de la CARD
     *  @return boolean
     */
    const develop = () => {
      if (total() < 1 && showNumberAction() < 1) {
        return false
      }
      return (isDevelop.value = !isDevelop.value)
    }
    /**
     *  isClosed : gere dynamiquement la class css pour l'afficher de la fleche
     *  @return string
     */
    const isClosed = () => {
      if (isDevelop.value) {
        return 'closed'
      }
      return 'opened'
    }

    /**
     *  fileHeader : Regarde si dans le store des fichiers de tetes ont été uploads
     *  @return number
     */
    const fileHeader = () => {
      let fileHeader = 0
      for (let i in files.value) {
        if (
          files.value[i].idDossier === _props.parentData.numDossier[0] &&
          files.value[i].idCompteur === _props.parentData.numDossier[0] &&
          files.value[i].uploadAndSend === false
        ) {
          fileHeader++
        }
      }
      return fileHeader
    }
    /**
     *  actionNecessaireHeader : retourne le nombre d'action restant à faire ( pour les DOC )
     *  @return number
     */
    const actionNecessaireHeader = () => {
      let downloadedFile = 0
      let necessaireList = 0

      for (let i in files.value) {
        if (files.value[i].idDossier === _props.parentData.numDossier[0]) {
          allFilesSended.value = false
        }
      }

      for (let i = 0; i < files.value.length; i++) {
        if (files.value[i].idDossier === _props.parentData.numDossier[0]) {
          downloadedFile++
        }
      }

      for (let i in listeDossiers.value[0].liste) {
        if (
          listeDossiers.value[0].liste[i].typeInfo === 'DOC' &&
          (listeDossiers.value[0].liste[i].valeurInfo === 'NECESSAIRE' ||
            listeDossiers.value[0].liste[i].valeurInfo ===
              'NECESSAIRE_SUR_PLACE') &&
          listeDossiers.value[0].liste[i].codeInfo !== '61_3'
        ) {
          necessaireList++
        }
      }

      return necessaireList - downloadedFile
    }
    /**
     *  showNumberAction : retourne le nombre d'action necessaire à faire ( Npour tout les NECESSAIRE )
     *  @return number
     */
    const showNumberAction = () => {
      let allFiles = 0
      let necessaireList = 0
      // pour chaque dossier, calcul le nombre de fichier à upload en fonction de la valeur info

      for (let i in _props.parentData.liste) {
        if (
          _props.parentData.liste[i].valeurInfo === 'NECESSAIRE' ||
          _props.parentData.liste[i].valeurInfo === 'NECESSAIRE_SUR_PLACE' ||
          (_props.parentData.liste[i].typeInfo === 'CHECK' &&
            _props.parentData.liste[i].valeurInfo !== 'RECU')
        ) {
          if (_props.parentData.liste[i].codeInfo !== '61_3') {
            necessaireList++
          }
        }
      }
      for (let i = 0; i < files.value.length; i++) {
        if (
          files.value[i].idDossier === _props.parentData.numDossier[0] &&
          files.value[i].header
        ) {
          allFiles++
        }
      }
      if (necessaireList === 0) {
        allFilesSended.value = true
      }
      /*if (listeDossiers.value[0].codeStatut === '62') {
        allFilesSended.value = true
        return 0
      }*/
      return necessaireList - allFiles
    }
    const nonNecessaire = () => {
      let necessaire = 0

      for (let j in listeDossiers.value[0].liste) {
        if (
          ((listeDossiers.value[0].liste[j].valeurInfo === 'NECESSAIRE' ||
            listeDossiers.value[0].liste[j].valeurInfo ===
              'NECESSAIRE_SUR_PLACE') &&
            listeDossiers.value[0].liste[j].typeInfo === 'INFO') ||
          (listeDossiers.value[0].liste[j].valeurInfo ===
            'NECESSAIRE_SUR_PLACE' &&
            listeDossiers.value[0].liste[j].typeInfo === 'DOC')
        ) {
          necessaire++
        }
      }
      return necessaire
    }
    /**
     *  showUploadPanel : fonction pour ouvrir le panel pour upload un fichier
     */
    const showUploadPanel = (e, compteur, idDossier, index, renvoyer) => {
      e.preventDefault()

      showPanel({
        component: {
          template: UploadPanel,
        },
        content: {
          compteur,
          idDossier,
          idCompteur: idDossier,
          ean: _props.parentData.ean,
          numCompteur: index,
          header: true,
          renvoyer,
        },
      })
    }
    /**
     *  showConsentementPanel : fonction pour ouvrir le panel de consentement
     */
    const showConsentementPanel = (e, idDossier) => {
      e.preventDefault()

      showPanel({
        component: {
          template: ConsentementPanel,
        },
        content: {
          idDossier,
          idCpt: idDossier,
          index: 0,
        },
      })
    }
    /**
     *  fileAlreadyUpload : retourne vrai si un fichier a déjà été upload avec les parametres
     *  @return boolean
     */
    const fileAlreadyUpload = (fileCode, index, id) => {
      for (let i = 0; i < files.value.length; i++) {
        if (
          files.value[i].idFile === fileCode &&
          files.value[i].idDossier === id &&
          files.value[i].idCompteur === id &&
          files.value[i].index === index &&
          files.value[i].uploadAndSend === true
        ) {
          return true
        }
      }
      return false
    }
    /**
     *  processAction : retourne un string en changeant le %nbrAction pour le nombre d'action restant
     *  @return string
     */
    const processAction = (text: string, action: string) => {
      let html = text.replace('%nbrAction', action)
      if (Number(action) > 1) {
        html = html.replace('%verbe', i18nSuivi.value.verbDocuments)
      } else {
        html = html.replace('%verbe', i18nSuivi.value.verbDocument)
        html = html.replace('(s)', '')
        html = html.replace('les', 'le')
      }
      return html
    }
    const processPluriel = (text: string, action: string) => {
      let html = text.replace('%nbrAction', '' + action)
      if (Number(action) <= 1) {
        html = html.replace('(s)', '')
      }
      return html
    }
    /**
     *  cstFile : retourne un boolean si un consentement a déjà été envoyé ou non
     *  @return boolean
     */
    const cstFile = () => {
      // ON RECUPERE LE CONSENTEMENT DU STORE CAR IL VIENT DE LE VALIDER
      for (let i in consentement.value) {
        if (
          consentement.value[i].isSend &&
          consentement.value[i].idDossier === _props.parentData.numDossier[0] &&
          consentement.value[i].idCpt === _props.parentData.numDossier[0]
        ) {
          return false
        }
      }
      // SINON ON CHECK SI LE WS109 CONTIENT UN CONSENTEMENT
      for (let i in _props.parentData.wS109Actions) {
        if (_props.parentData.wS109Actions[i].typeAction === 'PRET') {
          return false
        }
      }
      // SI PAS ON CHECK SI LE FICHIER 61_3 EXISTE ET EN ETAT RECU SINON ALORS ON AFFICHE CONSENTEMENT
      for (let i in _props.parentData.liste) {
        if (
          _props.parentData.liste[i].codeInfo === '61_3' &&
          _props.parentData.liste[i].valeurInfo !== 'RECU' &&
          _props.parentData.liste[i].valeurInfo !== 'NON_NECESSAIRE'
        ) {
          return true
        }
      }
      return false
    }
    /**
     *  total : calcule le nombre max d'action obligatoire par le user
     *  @return number
     */
    const total = () => {
      let necessaire = 0
      for (let j in listeDossiers.value[0].liste) {
        if (
          listeDossiers.value[0].liste[j].valeurInfo === 'NECESSAIRE' ||
          listeDossiers.value[0].liste[j].valeurInfo === 'CHECK' ||
          listeDossiers.value[0].liste[j].valeurInfo === 'NECESSAIRE_SUR_PLACE'
        ) {
          necessaire++
        }
      }
      return necessaire
    }
    /**
     *  sendWs109 : envoie la confirmation au WS 109
     */
    const sendWs109 = (id, renvoyer) => {
      for (let i = 0; i < files.value.length; i++) {
        if (
          files.value[i].idDossier === id &&
          files.value[i].idCompteur === id &&
          files.value[i].index === 0
        ) {
          UPDATE_FILE({
            idDossier: id,
            idCompteur: id,
            idFile: files.value[i].idFile,
            index: 0,
            uploadAndSend: true,
          })
        }
      }
      if (!renvoyer) {
        SET_CONSENTEMENT({
          idDossier: id,
          idCpt: id,
          index: 0,
          isSend: false,
          header: true,
        })
        allFilesSended.value = true
        isSend.value = true
        axios
          .get(
            `${getApiUrl()}/works/confirmation?NumDossier=${id}&DateAccord=${moment().format(
              'DD/MM/YYYY'
            )}&TypeAction=UPLOAD_DOC`
          )
          .then()
          .catch(e => {
            allFilesSended.value = false
            logger.error('[SEND CONFIRMATION] Error WS109')
          })
      } else {
        loaderRenvoyer.value = true
        axios
          .get(
            `${getApiUrl()}/works/confirmation?NumDossier=${id}&DateAccord=${moment().format(
              'DD/MM/YYYY'
            )}&TypeAction=UPLOAD_DOC`
          )
          .then(resp => {
            if (resp.status === 200) {
              loaderRenvoyer.value = false
            }
          })
          .catch(e => {
            allFilesSended.value = false
            logger.error('[SEND CONFIRMATION] Error WS109')
          })
      }
    }
    const consentementInfo = id => {
      for (let i in consentement.value) {
        if (
          consentement.value[i].idCpt === id &&
          consentement.value[i].idDossier === id &&
          consentement.value[i].header
        ) {
          return false
        }
      }
      return true
    }
    return {
      i18n,
      i18nSuivi,
      listeDossiers,
      showLoader,
      allFilesSended,
      isSend,
      isDevelop,
      loaderRenvoyer,
      nonNecessaire,
      consentementInfo,
      total,
      sendWs109,
      actionNecessaireHeader,
      showNumberAction,
      processAction,
      processPluriel,
      develop,
      cstFile,
      showUploadPanel,
      showConsentementPanel,
      fileAlreadyUpload,
      isClosed,
      fileHeader,
    }
  },
})
