

























/* eslint-disable */
import { defineComponent, ref } from '@vue/composition-api'
import axios from 'axios'
import { getApiUrl } from '@/inc/app.config'
import { useActions, useMutations, useState } from '@u3u/vue-hooks'
import moment from 'moment'

export default defineComponent({
  name: 'consentement-panel',
  setup(_props, ctx) {
    const { SET_CONSENTEMENT } = useMutations('my', ['SET_CONSENTEMENT'])
    const { hidePanel } = useActions('sidePanel', ['hidePanel'])
    const { consentement, i18nSuivi } = useState('my', [
      'i18nSuivi',
      'consentement',
    ])
    const consentementSend = ref(false)

    const validate = (idDossier, idCpt, index) => {
      consentementSend.value = true
      SET_CONSENTEMENT({
        idDossier: idDossier,
        idCpt: idCpt,
        index: index,
        isSend: true,
        header: true
      })
      axios
        .get(
          `${getApiUrl()}/works/confirmation?NumDossier=${idDossier}&TypeAction=PRET&DateAccord=${moment().format(
            'DD/MM/YYYY'
          )}`
        )
        .then(response => {
          if (response.status === 200) {
            hidePanel()
          }
        })
        .catch(e => {
          console.log(e)
        })
    }
    return {
      validate,
      consentementSend,
      consentement,
      i18nSuivi,
    }
  },
})
